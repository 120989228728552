@tailwind base;
@tailwind components;
@tailwind utilities;
html {
  scroll-behavior: smooth;
}
body{
    background: #000;
    font-family: "Poppins", sans-serif;
}
.text-shadow-lg {
  text-shadow: 2px 4px 6px rgba(0, 0, 0, 0.6);
}

.box {
  width: 200px;
  aspect-ratio: 1;
  clip-path: polygon(100% 50%,75% 93.3%,25% 93.3%,0% 50%,25% 6.7%,75% 6.7%);
}
.contact-section {
  position: relative; 
  z-index: 1;
}

.float {
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 40px;
  right: 40px;
  background-color: #25d366;
  color: #fff;
  border-radius: 50%;
  text-align: center;
  font-size: 30px;
  z-index: 100;
  transition: transform 0.3s ease-in-out;
}

.float:hover {
  transform: scale(1.1);
}

.my-float {
  margin-top: 16px;
}


.honeycomb
{
  display: -webkit-box;
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  max-width: 1440px;
  margin: 0 auto;
  padding: 0;
  transform: translateY(80px);
}

.honeycomb-cell
{
  -webkit-box-flex: 0;
  flex: 0 1 250px;
  max-width: 250px;
  height: 137.5px;
  margin: 65.4px 12.5px 25px;
  position: relative;
  padding: 0.5em;
  text-align: center;
  z-index: 1;
  box-shadow: 0px 0px 15px 0 rgba(0,0,0,0.1);
  
}
.honeycomb-cell_img
{
  object-fit: cover;
  object-position: center;

}
.honeycomb-cell_title
{
  height: 100%;
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-hyphens: auto;
  hyphens: auto;
  word-break: break-word;
  text-transform: uppercase;
  color: transparent;
  font-weight: 700;
  font-size: 1.75em;
  transition: opacity 350ms;
}
.honeycomb-cell_title > small
{
  font-weight: 300;
  margin-top: 0.25em;
}
.honeycomb-cell::before,
.honeycomb-cell::after
{
  content: '';
}
.honeycomb-cell::before,
.honeycomb-cell::after,
.honeycomb-cell_img
{
  top: -50%;
  left: 0;
  width: 100%;
  height: 200%;
  display: block;
  position: absolute;
  -webkit-clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
  clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
  z-index: -1;
}
.honeycomb-cell::before
{
  background: #eec33d;
  transform: scale(1.055);
}
.honeycomb-cell::after
{
  background: #111111;
  opacity: 0;
  transition: opacity 350ms;
  -webkit-transition: opacity 350ms;
}
.honeycomb-cell:hover
.honeycomb-cell_title
{
  opacity: 1;
  color: #fff;
}
.honeycomb-cell:hover
.honeycomb-cell_img
{
  filter: grayscale(40%);
}
.honeycomb-cell:hover::before
{
  background: #eec33e;
}
.honeycomb-cell:hover::after
{
  opacity: 0.7;
}
.honeycomb_Hidden
{
  display: none;
  opacity: 0;
  width: 250px;
  margin: 0 12.5px;
}
